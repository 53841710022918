.titleText {
    font-family: IBMPlexSansSemiBold !important;
    text-align: center !important;
    color: #677788 !important;
    font-size: 30px !important;
}

.contentText {
    font-family: IBMPlexSansRegular !important;
    text-align: center !important;
    font-size: 16px !important;
    color: #666 !important;
}
