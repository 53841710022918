.buttonView {
    background-color: #5a8dee !important;
    font-family: IBMPlexSansMedium !important;
    color: #FFF !important;
}

.btnView {
    text-transform: capitalize !important;
    font-size: 14px !important;
    line-height: 18px !important;
    border-radius: 4px !important;
}

.buttonHoverView:hover {
    background-color: #7ba4f1 !important;
    font-family: IBMPlexSansMedium !important;
    color: #FFF !important;
}

.deleteButtonView {
    background-color: red !important;
    font-family: IBMPlexSansMedium !important;
    color: #FFF !important;
}

.deleteButtonHoverView {
    background-color: red !important;
    font-family: IBMPlexSansMedium !important;
    color: #FFF !important;
}


.bookAgainButtonView {
    background-color: gray !important;
    font-family: IBMPlexSansMedium !important;
    color: #FFF !important;
    border-radius: 0px !important;
}

.bookAgainButtonHoverView {
    background-color: gray !important;
    font-family: IBMPlexSansMedium !important;
    color: #FFF !important;
    border-radius: 0px !important;
}
