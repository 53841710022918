.profileView {
  display: flex;
  align-items: center;
}

.profileImg {
  margin-right: 14px;
  border-radius: 50%;
  cursor: pointer;
}

.userNameText {
  font-size: 15px !important;
  font-family: IBMPlexSansBold !important;
}

.adminText {
  font-size: 12px !important;
  font-family: IBMPlexSansRegular !important;
}

.logoutView {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logoutText {
  font-size: 15px !important;
  margin-left: 10px !important;
  font-family: IBMPlexSansRegular !important;
}
