.imgView {
  height: 37px !important;
  width: 37px !important;
}

.imgStyle {
  height: 100% !important;
  width: 100% !important;
}

.listItemBtn {
  min-height: 43px !important;
  border-radius: 0.3125rem !important;
  padding: 0 12px !important;
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.subListItemBtn {
  min-height: 43px !important;
  border-radius: 0.3125rem !important;
}
