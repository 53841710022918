.cardView {
    background-color: #ffffff !important;
    border-radius: 2px;
    z-index: 1000;
    box-shadow: 0 2px 14px rgba(38, 60, 85, 0.16);
}

.tableView {
    overflow-x: auto !important;
}

.slotDateText {
    color: #677788 !important;
    font-size: 0.85rem !important;
    font-family: IBMPlexSansRegular !important;
}

.dataView {
    width: 15% !important;
}

.showingEntriesText {
    font-family: IBMPlexSansRegular !important;
    color: #a8b1bb !important;
    font-size: 0.95rem !important;
}

.noMatchFoundText {
    font-family: IBMPlexSansRegular !important;
    color: #516377 !important;
    font-size: 15px !important;
}
