.cardView {
    background-color: #ffffff !important;
    border-radius: 2px;
    z-index: 1000;
    box-shadow: 0 2px 14px rgba(38, 60, 85, 0.16);
}

.titleText {
    font-family: RubikSemiBold !important;
    text-align: center !important;
    font-size: 23px !important;
    color: #516377 !important;
}

.requiredText {
    color: red !important;
    font-family: IBMPlexSansBold !important;
    font-size: 12px !important
}

.labelText {
    font-family: IBMPlexSansMedium !important;
    color: #888 !important;
    font-size: 15px !important;
    margin-bottom: 6px !important;
}

.valueText {
    color: #888 !important;
    font-family: IBMPlexSansRegular !important;
    font-size: 15px !important
}
